// ----- colors
$clrLpBase: #333;
$clrTtl:#1b1b1b;
$clrLpMain:#da0001;
$clrLpLink: #333;
$clrLpLinkHover: #333;
$clrLpBlue:#00e6ff;
$clrLpRed:#da0001;
$clrBlk: #1b1b1b;
$clrWht: #fff;
$clrBg: #2a2b2c;
$clrBg02:#f2f2f2;
$clrGry: #888;
$clrGry01: #f2f2f2;
$clrBorder: #ddd;
$clrLBlue:#e4edf5;
$clrYlw:#ffd135;