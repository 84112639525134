/*　ハンバーガーボタン　*/

.l-header_nav {
    &_hamb {
        display: block;
        // position: fixed;
        position: relative;
        z-index: 9999;
        // right: 10px;
        // top: 10px;
        width: 90px;
        height: 90px;
        cursor: pointer;
        text-align: center;
        background-color: $clrLpRed;
        @media #{$querySp} {
            width: 60px;
            height: 60px;
        }
        p {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            top: 50px;
            color: $clrWht;
            line-height: 1;
            @include fs(14);
            &::before {
                content: "menu";
                -webkit-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                -webkit-transition-delay: 0.6s;
                -o-transition-delay: 0.6s;
                transition-delay: 0.6s;
            }
            @media #{$querySp} {
                @include fs(10);
                top: 37px;
            }
        }
        span {
            display: block;
            position: absolute;
            width: 40px;
            height: 2px;
            left: 50%;
            margin-left: -20px;
            background: $clrWht;
            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;
            &:nth-child(1) {
                top: 24px;
            }
            &:nth-child(2) {
                top: 32px;
            }
            &:nth-child(3) {
                top: 40px;
            }
            @media #{$querySp} {
                width: 30px;
                height: 2px;
                margin-left: -15px;
                &:nth-child(1) {
                    top: 12px;
                }
                &:nth-child(2) {
                    top: 21px;
                }
                &:nth-child(3) {
                    top: 30px;
                }
            }
        }
        &.active {
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            -webkit-transition-delay: 0.6s;
            -o-transition-delay: 0.6s;
            transition-delay: 0.6s;
            // -webkit-transform: rotate(45deg);
            // -ms-transform: rotate(45deg);
            // -o-transform: rotate(45deg);
            // transform: rotate(45deg);
            p {
                &::before {
                    content: "close";
                    -webkit-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                    -webkit-transition-delay: 0.6s;
                    -o-transition-delay: 0.6s;
                    transition-delay: 0.6s;
                }
            }
            span {
                background: $clrWht;
                &:nth-child(2) {
                    width: 0px;
                }
                &:nth-child(1),
                &:nth-child(3) {
                    -webkit-transition-delay: 0.3s;
                    -o-transition-delay: 0.3s;
                    transition-delay: 0.3s;
                }
                &:nth-child(1) {
                    -webkit-transform: translateY(9px) rotate(-156deg);
                    -ms-transform: translateY(9px) rotate(-156deg);
                    -o-transform: translateY(9px) rotate(-156deg);
                    transform: translateY(9px) rotate(-156deg);
                    top: 21px;
                    @media #{$querySp} {
                        -webkit-transform: translateY(7px) rotate(-145deg);
                        -ms-transform: translateY(7px) rotate(-145deg);
                        -o-transform: translateY(7px) rotate(-145deg);
                        transform: translateY(7px) rotate(-145deg);
                    }
                }
                &:nth-child(3) {
                    -webkit-transform: translateY(-7px) rotate(156deg);
                    -ms-transform: translateY(-7px) rotate(156deg);
                    -o-transform: translateY(-7px) rotate(156deg);
                    transform: translateY(-7px) rotate(156deg);
                    top: 37px;
                    @media #{$querySp} {
                        -webkit-transform: translateY(-6px) rotate(145deg);
                        -ms-transform: translateY(-6px) rotate(145deg);
                        -o-transform: translateY(-6px) rotate(145deg);
                        transform: translateY(-6px) rotate(145deg);
                    }
                }
                @media #{$querySp} {
                    &:nth-child(1) {
                        width: 32px;
                        top: 15px;
                    }
                    &:nth-child(3) {
                        width: 32px;
                        top: 28px;
                    }
                }
            }
        }
    }
    &_gmenu {
        position: fixed;
        z-index: 3;
        top: 0;
        right: 0;
        color: $clrWht;
        background: $clrLpRed;
        text-align: center;
        transform: translateX(100%);
        transition: all .9s;
        width: 32%;
        height: 100%;
        padding: 0 3%;
        @media #{$querySp} {
            width: 89%;
            padding: 0 8.23%;
        }
        ul {
            margin: 0 auto;
            padding: 0;
            width: 100%;
            padding-top: 11.8519vh;
            @media #{$querySp} {
                padding-top: 42%;
                padding-top: 21.05vw;
            }
            li {
                list-style-type: none;
                padding: 0;
                width: 100%;
                margin-bottom: 6.6667vh;
                @media #{$querySp} {
                    margin-bottom: 12.63vw;
                }
                &:last-child {
                    padding-bottom: 0;
                    border-bottom: none;
                }
                &:hover {
                    // background: #ddd;
                }
                a {
                    display: block;
                    color: $clrWht;
                    text-decoration: none;
                    @include fs(24);
                    @media #{$querySp} {
                        @include fs(24);
                    }
                    span {
                        line-height: 1;
                        text-align: center;
                        display: block;
                        font-weight: 900;
                    }
                    .u-ff {
                        &_en {
                            @include fs(16);
                            margin-top: 10px;
                            @media #{$querySp} {
                                @include fs(12);
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
        }
        &.active {
            transform: translateX(0%);
        }
        .c-btn_cv {
            opacity: 1;
            display: block;
            min-width: 280px;
            height: 80px;
            @media #{$querySp} {
                min-width: auto;
                width: 100%;
            }
        }
    }
}


/* アニメーション定義 */

@keyframes slideOutIn {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    30% {
        opacity: 0;
        transform: translateX(20px);
    }
    50% {
        opacity: 0;
        transform: translateX(-30px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.l-header_nav_hamb.hovered {
    span {
        &:nth-child(1) {
            animation: slideOutIn 0.8s forwards 0.2s;
        }
        &:nth-child(2) {
            animation: slideOutIn 0.8s forwards;
        }
        &:nth-child(3) {
            animation: slideOutIn 0.8s forwards 0.3s;
        }
    }
}