.c-key {
    &_page {
        margin-top: 108px;
        position: relative;
        overflow-x: hidden;
        @media #{$querySp} {
            margin-top: 68px;
        }
        &_inner {}
        &_txt {
            padding: 42px 0 46px;
            @media #{$querySp} {
                padding: 20px 0 24px;
            }
            &_main {
                font-weight: bold;
                line-height: 1;
                font-feature-settings: "palt";
                @media #{$querySp} {
                    font-size: 7.2vw;
                }
                .-en {
                    display: block;
                    font-family: $fontFamilyEn;
                    @include fs(70);
                    color: $clrTtl;
                    margin-bottom: 12px;
                    padding-left: 70px;
                    position: relative;
                    &::before {
                        content: "";
                        display: inline-block;
                        background-color: $clrTtl;
                        position: absolute;
                        width: 48px;
                        height: 6px;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        @media #{$querySp} {
                            width: 28px;
                            height: 3px;
                        }
                    }
                    @media #{$querySp} {
                        font-size: 9vw;
                        margin-bottom: 0;
                        padding-left: 40px;
                    }
                }
                .-jp {
                    display: inline-block;
                    @include fs(26);
                    color: $clrGry;
                    padding-left: 70px;
                    @media #{$querySp} {
                        font-size: 5vw;
                        padding-left: 40px;
                    }
                }
            }
        }
        &_img {
            height: 440px;
            position: relative;
            @media #{$querySp} {
                height: 180px;
            }
            &::before {
                content: "";
                display: inline-block;
                background-image: url(../img/page/kv.jpg);
                background-position: top center;
                background-repeat: no-repeat;
                background-size: cover;
                @media #{$querySp} {
                    background-image: url(../img/page/kv.jpg);
                }
                position: absolute;
                width:100vw;
                height:100%;
                top: 0;
                left: 0;
            }
        }
    }
    &-lp {
        .c-key {
            &_page {
                margin-top: 108px;
                position: relative;
                overflow-x: hidden;
                @media #{$querySp} {
                    margin-top: 68px;
                }
                &_inner {}
                &_txt {
                    padding: 42px 0 46px;
                    @media #{$querySp} {
                        padding: 20px 0 24px;
                    }
                    &_main {
                        font-weight: bold;
                        line-height: 1;
                        font-feature-settings: "palt";
                        @media #{$querySp} {
                            font-size: 7.2vw;
                        }
                        .-en {
                            display: block;
                            font-family: $fontFamilyEn;
                            @include fs(70);
                            color: $clrTtl;
                            margin-bottom: 12px;
                            padding-left: 70px;
                            position: relative;
                            &::before {
                                content: "";
                                display: inline-block;
                                background-color: $clrTtl;
                                position: absolute;
                                width: 48px;
                                height: 6px;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                                @media #{$querySp} {
                                    width: 28px;
                                    height: 3px;
                                }
                            }
                            @media #{$querySp} {
                                font-size: 9vw;
                                margin-bottom: 0;
                                padding-left: 40px;
                            }
                        }
                        .-jp {
                            display: inline-block;
                            @include fs(26);
                            color: $clrGry;
                            padding-left: 70px;
                            @media #{$querySp} {
                                font-size: 5vw;
                                padding-left: 40px;
                            }
                        }
                    }
                }
                &_img {
                    height: 440px;
                    position: relative;
                    @media #{$querySp} {
                        height: 180px;
                    }
                    &::before {
                        content: "";
                        display: inline-block;
                        background-image: url(../img/page/kv.jpg);
                        background-position: top center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        @media #{$querySp} {
                            background-image: url(../img/page/kv.jpg);
                        }
                        position: absolute;
                        width:100vw;
                        height:100%;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}

.service {
    .c-key_page_img::before {
        background-image: url(../img/service/kv.jpg);
        // @media #{$querySp} {
        //     background-image: url(../img/service/kv.jpg);
        // }
    }
}

.philosophy {
    .c-key_page_img::before {
        background-image: url(../img/philosophy/kv.jpg);
        // @media #{$querySp} {
        //     background-image: url(../img/philosophy/kv.jpg);
        // }
    }
}

.register {
    .c-key_page_img::before {
        background-image: url(../img/register/kv.jpg);
        // @media #{$querySp} {
        //     background-image: url(../img/philosophy/kv.jpg);
        // }
    }
}

.contact {
    .c-key_page_img::before {
        background-image: url(../img/contact/kv.jpg);
        // @media #{$querySp} {
        //     background-image: url(../img/philosophy/kv.jpg);
        // }
    }
}

.terms {
    .c-key_page_img::before {
        background-image: url(../img/terms/kv.jpg);
        // @media #{$querySp} {
        //     background-image: url(../img/philosophy/kv.jpg);
        // }
    }
}

.c-breadcrumbs {
    &_list {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
        padding: 12px 0;
        @media #{$querySp} {
            padding: 10px 0;
        }
        li {
            @include fs(13);
            margin-left: 16px;
            @media #{$querySp} {
                @include fs(12);
            }
            &:not(:last-child) {
                position: relative;
                &::after {
                    content: "/";
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    right: -12px;
                    transform: translateY( -50%);
                }
            }
            a {}
        }
    }
}