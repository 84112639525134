[class^="c-btn"] {
    @include fs(16);
    line-height: 1.3;
    font-weight: bold;
    display: inline-block;
    transition: all .4s;
    @media #{$querySp} {
        @include fs(14);
    }
    a {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        height: 100%;
        &:hover {
            opacity: 1;
        }
    }
    &:hover {
        transition: all .4s;
    }
}

.c-btn {
    &_cv {
        min-width: 320px;
        height: 56px;
        border-radius: 40px;
        overflow: hidden;
        @media #{$querySp} {
            min-width: unset;
            width: 80%;
            height: 45px;
        }
        a {
            justify-content: center;
            flex-direction: column;
            position: relative;
            padding: 0 54px;
            span {
                display: block;
                text-align: center;
            }
            .u-ff {
                &_en {
                    color: $clrLpRed;
                    @include fs(32);
                    line-height: 1;
                }
                &_jp {
                    font-weight: 900;
                    @include fs(16);
                }
            }
            &::after {
                content: "";
                display: inline-block;
                background-image: url(../img/lp/arw_red.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                width: 16px;
                height: 9px;
                top: 50%;
                right: 28px;
                transform: translateY(-50%);
                transition: .4s;
            }
            &:hover {
                &::after {
                    right: 23px;
                    transition: .4s;
                }
            }
        }
        &.-blue {
            background: rgb(0, 230, 255);
            background: linear-gradient(90deg, rgba(0, 230, 255, 1) 0%, rgba(100, 240, 71, 1) 100%);
            background-size: 100% 100%;
            transition: all .4s;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(90deg, rgba(0, 230, 255, 1) 33%, rgba(100, 240, 71, 1) 133%);
                width: 100%;
                height: 100%;
                transform: scale(0, 1);
                transform-origin: right top;
                transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
            }
            &:hover {
                transition: all .4s;
                // background: linear-gradient(90deg, rgba(0, 230, 255, 1) 33%, rgba(100, 240, 71, 1) 133%);
                &::before {
                    transform-origin: left top;
                    transform: scale(1, 1);
                }
            }
        }
    }
    &_base {
        min-width: 320px;
        height: 51px;
        border-radius: 26px;
        position: relative;
        overflow: hidden;
        @media #{$querySp} {
            min-width: unset;
            width: 80%;
            height: 43px;
            margin: auto;
        }
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            margin: auto;
            transform: scale(0, 1);
            transform-origin: right top;
            transition: transform .4s;
            z-index: 1;
        }
        &:hover::before {
            transform-origin: left top;
            transform: scale(1, 1);
        }
        a {
            position: relative;
            z-index: 10;
        }
        &.-blk {
            background-color: $clrBg;
            border: 1px solid $clrBg;
            a {
                color: $clrWht;
            }
            &:hover {
                a {
                    color: $clrLpBase;
                }
                &::before {
                    background: $clrWht;
                }
            }
        }
        &.-bgWht {
            background-color: $clrWht;
            border: 1px solid $clrLpMain;
            a {
                color: $clrLpMain;
            }
            &:hover {
                a {
                    color: $clrWht;
                }
                &::before {
                    background: $clrLpMain;
                }
            }
        }
    }
    &_wrap {
        @media #{$queryPc} {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }
        @media #{$querySp} {
            text-align: center;
        }
        .c-btn_base {
            &:not(:first-child) {
                @media #{$queryPc} {
                    margin-left: 32px;
                }
                @media #{$querySp} {
                    margin-top: 10px;
                }
            }
            @media #{$querySp} {
                display: inline-block;
            }
        }
    }
    &_submit {
        display: inline-block;
        position: relative;
        transition: .4s;
        cursor: pointer;
        background-color: $clrLpRed;
        border: 1px solid $clrLpRed;
        border-radius: 40px;
        overflow: hidden;
        @media #{$querySp} {
            min-width: unset;
            width: 100%;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background: #fff;
            width: 100%;
            height: 100%;
            transform: scale(0, 1);
            transform-origin: right top;
            transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
        }
        &::after {
            content: "";
            display: inline-block;
            background-image: url(../img/lp/arw_btn.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position: absolute;
            width: 16px;
            height: 9px;
            top: 50%;
            right: 28px;
            transform: translateY(-50%);
            z-index: 10;
            transition: .4s;
            @media #{$querySp} {
                right: 26px;
            }
        }
        input,
        .p-contact_form_submit {
            text-align: center;
            min-width: 400px;
            width: auto;
            padding: 28px 54px 30px;
            display: inline-block;
            @include fs(18);
            font-weight: bold;
            background: none;
            color: $clrWht;
            z-index: 10;
            position: relative;
            transition: .4s;
            @media #{$querySp} {
                min-width: unset;
                width: 100%;
                padding: 18px 32px 20px;
            }
        }
        &:hover {
            &::before {
                transform-origin: left top;
                transform: scale(1, 1);
            }
            &::after {
                right: 23px;
                transition: .4s;
                background-image: url(../img/lp/arw_btn_red.svg);
            }
            input,
            .p-contact_form_submit {
                color: $clrLpRed;
                transition: .4s;
            }
        }
    }
    &_pagetop {
        display: block;
        position: fixed;
        right: 2.0833vw;
        bottom: 40px;
        width: 50px;
        z-index: 9999;
        @media #{$querySp} {
            right: 5%;
            bottom: 4.1666vw;
        }
        img {
            width: 100%;
        }
    }
}

.lp_active {
    .c-btn_pagetop {
        z-index: -1;
    }
}