.c-key-lp {
    // height: 40.625vw;
    // min-height: 720px;
    height: 780px;
    position: relative;
    @media screen and (min-width: 1px) {}
    @media #{$querySp} {
        // height: 78vh;
        height: auto;
    }
    .c-key {
        &_inner {
            width: 100%;
            height: 100%;
            position: relative;
            background-image: url(../img/lp/main_bg.png);
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow-x: hidden;
            // @media #{$querySp} {
            //     background-image: url(../img/index/mv_sp.jpg);
            // }
            &_img {
                position: absolute;
                // right: -7.2917vw;
                // top: -7.8125vw;
                // width: 61.3021vw;
                right: -8.4vw;
                top: -7.3vw;
                width: 62.5vw;
                z-index: 10;
                @media screen and (min-width: 1920px) {
                    width: 1177px;
                }
                img {
                    width: 100%;
                }
                @media #{$querySp} {
                    position: static;
                    width: 120vw;
                }
            }
        }
        &_txt {
            position: absolute;
            width: 100%;
            top: calc(90px + 1.5625vw);
            left: 0;
            z-index: 100;
            color: $clrTtl;
            // text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
            @media #{$querySp} {
                position: static;
                padding: 8px 0 40px;
            }
            .-txt {
                display: block;
                line-height: 1;
                margin-bottom: 12px;
                .u-bg_blue-gr {
                    color: $clrLpBase;
                    font-weight: 900;
                    display: inline-block;
                }
            }
            &_main {
                letter-spacing: -0.1em;
                @include fs(50);
                color: rgba($clrWht, 0.9);
                font-weight: 900;
                line-height: 1.5;
                margin-bottom: 32px;
                font-feature-settings: "palt";
                font-style: italic;
                @media #{$querySp} {
                    font-size: 7.105vw;
                    margin-bottom: 15px;
                }
                .-txt {
                    letter-spacing: .01em;
                    .u-bg_blue-gr {
                        @include fs(62);
                        padding: 2px 12px 10px;
                        @media #{$querySp} {
                            font-size: 8.68vw;
                            padding: 2px 10px 8px;
                        }
                    }
                }
            }
            &_sub {
                .-txt {
                    line-height: 1.65;
                    color: $clrWht;
                    @include fs(20);
                    @media #{$querySp} {
                        @include fs(16);
                    }
                    @media screen and (max-width: 375px) {
                        font-size: 4vw;
                    }
                    .u-bg_blue-gr {
                        @include fs(40);
                        line-height: 1;
                        padding: 6px 12px 10px;
                        margin-right: 5px;
                        @media #{$querySp} {
                            font-size: 5.78vw;
                            padding: 6px 10px 8px;
                        }
                    }
                }
            }
        }
    }
}

.p-lp {
    &_main {
        position: relative;
        &::after {
            content: "";
            display: inline-block;
            background-image: url(../img/lp/bg_mainbtm.png);
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: contain;
            position: absolute;
            width: 25.3646vw;
            height: 19.7917vw;
            bottom: 0;
            left: -2px;
            z-index: -1;
            @media #{$querySp} {
                width: 52.63vw;
                height: 39.47vw;
            }
        }
    }
    &_date {
        position: relative;
        // background-color: $clrWht;
        margin-top: -8vw;
        &::before {
            content: "";
            display: inline-block;
            background-image: url(../img/lp/date_bg.png);
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: auto 100%;
            position: absolute;
            width: 100%;
            height: 97%;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
        }
        // &::before {
        //     content: "";
        //     // background-color: $clrWht;
        //     display: block;
        //     position: absolute;
        //     width: 100%;
        //     // height: 17.0834vw;
        //     // height: 304px;
        //     // bottom: -152px;
        //     // height: 100%;
        //     // height: 7.9167vw;
        //     height: 8vw;
        //     bottom: 100%;
        //     left: 0;
        //     z-index: 1;
        //     // border-radius: 50% 50% 0 0;
        //     background-image: url(../img/lp/main_btm.svg);
        //     background-position: bottom;
        //     background-repeat: no-repeat;
        //     // background-size: 100% auto;
        //     background-size: auto 100%;
        //     // overflow-y: hidden;
        // }
        &_bg {
            img {
                width: 100%;
            }
        }
        .l-lpcont_m {
            height: 400px;
            position: relative;
            background-color: $clrWht;
            @media #{$querySp} {
                height: 71vw;
            }
        }
        &_inner {
            text-align: center;
            z-index: 10;
            padding-bottom: 40px;
            position: absolute;
            top: -3vw;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            @media #{$querySp} {
                position: relative;
                transform: unset;
                left: 0;
                top: 0;
                padding-top: 5px;
            }
        }
        &_year {
            font-family: $fontFamilyEn;
            @include fs(72);
            font-weight: 900;
            line-height: 1;
            text-align: center;
            @media #{$querySp} {
                font-size: 8.06vw;
                margin-bottom: 5px;
            }
            span {
                font-family: $fontFamily;
                @include fs(32);
                @media #{$querySp} {
                    font-size: 3.42vw;
                }
            }
        }
        &_day {
            display: inline-flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: center;
            position: relative;
            z-index: 10;
            margin-top: -6px;
            &::after {
                content: "";
                display: inline-block;
                background: #00e6ff;
                background: linear-gradient(90deg, #00e6ff 0, #64f047 100%);
                position: absolute;
                width: calc(100% + 24px);
                height: 8px;
                bottom: -4px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                @media #{$querySp} {
                    width: calc(100% + 12px);
                    height: 3px;
                    bottom: -1px;
                }
            }
        }
        &_open {
            position: relative;
            z-index: 10;
            line-height: 1;
            color: $clrLpRed;
            @include fs(120);
            font-weight: 900;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            letter-spacing: -0.02em;
            @media #{$querySp} {
                font-size: 13.42vw;
            }
            .-txt {
                @include fs(48);
                font-family: $fontFamily;
                @media #{$querySp} {
                    font-size: 5.37vw;
                }
            }
            .-num {
                display: inline-block;
                margin-bottom: -0.14em;
                font-family: $fontFamilyEn;
                &_01 {
                    letter-spacing: -0.12em;
                }
            }
            .-week {
                font-family: $fontFamily;
                display: inline-block;
                @include fs(30);
                color: $clrLpBlue;
                background-color: $clrLpBase;
                border-radius: 4px;
                width: 42px;
                height: 42px;
                line-height: 40px;
                text-align: center;
                margin-left: 5px;
                @media #{$querySp} {
                    font-size: 3.3vw;
                    width: 20px;
                    height: 20px;
                    line-height: 18px;
                    border-radius: 2px;
                }
            }
        }
        &_arw {
            width: 64px;
            height: 56px;
            padding-bottom: 14px;
            margin: 0 30px;
            display: inline-block;
            @media #{$querySp} {
                width: 7.1vw;
                height: 5.3vw;
                padding-bottom: 0;
                margin: 0 3.15vw;
            }
            @media screen and (max-width: 375px) {
                width: 6vw;
                height: 5.3vw;
                margin: 0 2vw;
            }
            img {
                height: 100%;
                width: auto;
            }
        }
        &_spbb {
            width: 560px;
            margin: 22px auto 0;
            @media #{$querySp} {
                width: 100%;
                margin: 10px auto 0;
            }
        }
        &_note {
            margin-top: 10px;
            @media #{$querySp} {
                margin-top: 8px;
            }
        }
    }
    &_tour {
        background-image: url(../img/lp/bg_stripe.png);
        background-repeat: repeat;
        position: relative;
        z-index: 1;
        text-align: center;
        padding-bottom: 210px;
        @media #{$querySp} {
            padding-bottom: 80px;
        }
        &::before {
            content: "";
            display: block;
            background: $clrWht;
            width: 100%;
            height: 90px;
            position: absolute;
            border-radius: 0 0 90px 90px;
            left: 0;
            top: 0;
            z-index: 10;
            @media #{$querySp} {
                height: 50px;
                border-radius: unset;
            }
        }
        &::after {
            content: "";
            display: block;
            background: $clrWht;
            width: 100%;
            height: 90px;
            position: absolute;
            border-radius: 90px 90px 0 0;
            left: 0;
            bottom: 0;
            z-index: 10;
            @media #{$querySp} {
                height: 32px;
                border-radius: 32px 32px 0 0;
            }
        }
        .l-lpcont_m {
            position: relative;
            &::before {
                content: "";
                display: inline-block;
                background-image: url(../img/lp/tour_icn01.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                width: 150px;
                height: 200px;
                top: 12px;
                left: -50px;
                z-index: 100;
                @media #{$querySp} {
                    width: 15.78vw;
                    height: 21.05vw;
                    top: 34.21vw;
                    left: 0;
                }
            }
        }
        &_ttl {
            width: 46.875vw;
            max-width: 900px;
            margin: auto;
            display: inline-block;
            background-image: url(../img/lp/bg_stripe.png);
            background-repeat: repeat;
            position: relative;
            z-index: 20;
            padding: 60px 20px 54px;
            border-radius: 40px 40px 0 0;
            @media #{$querySp} {
                width: 280px;
                padding: 30px 10px 16px;
                border-radius: 20px 20px 0 0;
            }
            &_txt {}
        }
        &_inner {}
        &_copy {
            @include fs(48);
            font-weight: 900;
            display: inline-block;
            position: relative;
            padding: 0 45px;
            margin-bottom: 34px;
            line-height: 1;
            @media #{$querySp} {
                font-size: 6.84vw;
                padding: 0 38px;
                margin-bottom: 15px;
            }
            &::before {
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                position: absolute;
                background-color: $clrLpBase;
                bottom: 45%;
                left: -12px;
                transform: rotate(60deg);
                @media #{$querySp} {
                    width: 30px;
                    height: 1px;
                    left: -5px;
                }
            }
            &::after {
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                position: absolute;
                background-color: $clrLpBase;
                bottom: 45%;
                right: -12px;
                transform: rotate(-60deg);
                @media #{$querySp} {
                    width: 30px;
                    height: 1px;
                    right: -5px;
                }
            }
        }
        &_list {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: space-between;
            @media #{$querySp} {
                display: block;
                width: 62.1vw;
                min-width: 237px;
                margin: auto;
            }
            li {
                width: 22.08%;
                position: relative;
                padding-bottom: 30px;
                @media #{$querySp} {
                    width: 100%;
                    padding-bottom: 24px;
                    &:not(:first-child) {
                        margin-top: 16px;
                    }
                }
                &::after {
                    content: "";
                    display: block;
                    background: $clrWht;
                    position: absolute;
                    width: 100%;
                    height: calc(100% - 50px);
                    bottom: 0;
                    left: 0;
                    border-radius: 20px;
                    z-index: 1;
                    @media #{$querySp} {
                        border-radius: 15px;
                    }
                }
                .-img {
                    width: 82.57%;
                    margin: auto;
                    position: relative;
                    z-index: 10;
                    margin-bottom: 12px;
                    img {
                        width: 100%;
                    }
                }
                .-txt {
                    line-height: 1.4;
                    @include fs(20);
                    font-weight: 900;
                    position: relative;
                    z-index: 10;
                    @media #{$querySp} {
                        font-size: 4.73vw;
                    }
                }
            }
        }
    }
    &_schedule {
        position: relative;
        padding-bottom: 38px;
        @media #{$querySp} {
            padding-bottom: 20px;
        }
        &::before {
            content: "";
            display: inline-block;
            background-image: url(../img/lp/tour_icn02.svg);
            background-repeat: no-repeat;
            background-position: top;
            background-size: contain;
            position: absolute;
            width: 33.3333vw;
            max-width: 640px;
            height: 640px;
            top: -280px;
            right: 0;
            z-index: 100;
            @media #{$querySp} {
                width: 52.63vw;
                height: 52.63vw;
                top: -36.31vw;
            }
        }
        &_ttl {
            text-align: center;
            padding-top: 30px;
            margin-bottom: 56px;
            @media #{$querySp} {
                padding-top: 16.84vw;
                margin-bottom: 30px;
            }
        }
        &_date {
            padding-bottom: 60px;
            @media #{$querySp} {
                padding-bottom: 30px;
            }
            &_cont {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                font-weight: 900;
                line-height: 1;
                &:first-child {
                    dd {
                        .-date {
                            span {
                                display: inline-block;
                                margin-left: 4px;
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    dd {
                        .-date {
                            span {
                                display: block;
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
        }
        &_time {
            padding: 45px 50px;
            background-image: url(../img/lp/bg_stripe.png);
            background-repeat: repeat;
            border-radius: 25px;
            @media #{$querySp} {
                padding: 30px 20px;
                border-radius: 15px;
            }
            &_ttl {
                font-weight: 900;
                padding-bottom: 20px;
                margin-bottom: 23px;
                border-bottom: 1px solid $clrLpBase;
                span {
                    display: block;
                    line-height: 1.4;
                }
                .-jp {
                    @include fs(30);
                    letter-spacing: 0.04em;
                    @media #{$querySp} {
                        font-size: 5.79vw;
                    }
                }
                .-en {
                    @include fs(18);
                    font-family: $fontFamilyEn;
                    color: $clrLpRed;
                    @media #{$querySp} {
                        @include fs(14);
                    }
                }
            }
            &_cont {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: space-between;
                @media #{$querySp} {
                    display: block;
                }
            }
            &_line {
                width: 480px;
                @media #{$querySp} {
                    width: 100%;
                }
            }
            &_img {
                width: calc(100% - 480px);
                @media #{$querySp} {
                    width: 100%;
                }
                img {
                    width: 100%;
                }
            }
        }
        &_slide {
            padding-top: 120px;
            overflow-x: hidden;
            @media #{$querySp} {
                padding-top: 30px;
            }
        }
        &_slider {
            &_item {
                img {
                    width: 100%;
                }
            }
            .slick-slide {
                height: auto!important;
                padding: 0 10px;
                @media #{$querySp} {
                    padding: 0 5px;
                }
                &:nth-child(even) {
                    img {
                        padding-top: 40px;
                        @media #{$querySp} {
                            padding-top: 20px;
                        }
                    }
                }
            }
        }
    }
    &_access {
        padding: 200px 0 60px;
        background-image: url(../img/lp/bg_stripe.png);
        background-repeat: repeat;
        position: relative;
        @media #{$querySp} {
            padding: 21.05vw 0 50px;
        }
        &::before {
            content: "";
            display: block;
            background: #fff;
            width: 100%;
            height: 90px;
            position: absolute;
            border-radius: 0 0 90px 90px;
            left: 0;
            top: 0;
            z-index: 10;
            @media #{$querySp} {
                height: 32px;
                border-radius: 0 0 32px 32px;
            }
        }
        &_inner {
            position: relative;
            &::before {
                content: "";
                display: inline-block;
                background-image: url(../img/lp/access_icn01.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                width: 150px;
                height: 200px;
                top: -90px;
                left: 13.4912%;
                z-index: 100;
                @media #{$querySp} {
                    width: 15.78vw;
                    height: 21.05vw;
                    top: -100px;
                    left: 3%;
                }
            }
        }
        &_ttl {
            text-align: center;
            padding-bottom: 60px;
            @media #{$querySp} {
                padding-bottom: 28px;
            }
        }
        &_cont {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-right: 1.718vw;
            padding-bottom: 110px;
            @media #{$querySp} {
                display: block;
                padding-bottom: 32px;
                padding-right: 0;
            }
        }
        &_list {
            .c-list_detail {
                &:not(:first-child) {
                    margin-top: 75px;
                    @media #{$querySp} {
                        margin-top: 24px;
                    }
                }
            }
            @media #{$querySp} {
                margin-bottom: 24px;
            }
        }
        &_img {
            padding-top: 12px;
            width: 16.9271vw;
            max-width: 325px;
            @media #{$querySp} {
                width: 265px;
                margin: auto;
                padding-top: 0;
            }
        }
        &_map {
            height: 500px;
            margin-bottom: 60px;
            @media #{$querySp} {
                height: 380px;
                margin-bottom: 40px;
            }
            iframe {
                width: 100%;
                height: 100%;
            }
        }
        &_notice {
            &_inner {
                padding: 40px 100px 38px;
                background-color: $clrWht;
                border-radius: 25px;
                width: 52.08vw;
                max-width: 1000px;
                margin: auto;
                @media #{$querySp} {
                    width: 100%;
                    padding: 30px 20px 20px;
                }
            }
            &_ttl {
                display: block;
                text-align: center;
                width: 160px;
                height: 32px;
                line-height: 31px;
                font-weight: 900;
                @include fs(20);
                margin: 0 auto 24px;
                background: #00e6ff;
                background: linear-gradient(90deg, #00e6ff 0, #64f047 100%);
                @media #{$querySp} {
                    margin: 0 auto 15px;
                    @include fs(18);
                }
            }
        }
    }
    &_entry {
        padding: 120px 0 110px;
        @media #{$querySp} {
            padding: 38px 0 50px;
        }
        &_inner {
            position: relative;
            &::before {
                content: "";
                display: inline-block;
                background-image: url(../img/lp/entry_icn01.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                width: 150px;
                height: 200px;
                top: -58px;
                right: 10.52%;
                z-index: 100;
                @media #{$querySp} {
                    width: 21.05vw;
                    height: 21.05vw;
                    right: 5%;
                    top: -8px;
                }
            }
        }
        &_ttl {
            text-align: center;
            padding-bottom: 60px;
            @media #{$querySp} {
                padding-bottom: 26px;
            }
        }
        &_head {
            text-align: center;
            margin-bottom: 34px;
            @media #{$querySp} {
                margin-bottom: 26px;
            }
            .u-clr_LpRed {
                font-weight: 900;
            }
            &_first {
                margin-bottom: 20px;
                line-height: 1.6;
                @media #{$querySp} {
                    font-size: 4.21vw;
                    .u-clr_LpRed {
                        font-size: 4.73vw;
                    }
                }
            }
            &_read {
                font-weight: 900;
                line-height: 1.4;
                @include fs(24);
                @media #{$querySp} {
                    font-size: 5.263vw;
                }
            }
        }
        &_contact {
            padding: 50px 5.2083vw 48px;
            background-image: url(../img/lp/bg_stripe.png);
            background-repeat: repeat;
            border-radius: 25px;
        }
        &_tel {
            text-align: center;
            margin-bottom: 40px;
            @media #{$querySp} {
                margin-bottom: 20px;
            }
            &_num {
                margin-bottom: 16px;
                .-tel {
                    color: $clrLpRed;
                    font-weight: 900;
                    @include fs(54);
                    line-height: 1;
                    @media #{$querySp} {
                        font-size: 11.05vw;
                    }
                }
                .-txt {
                    @media #{$querySp} {
                        @include fs(16);
                    }
                }
            }
            &_note {
                display: inline-block;
                p {
                    text-align: left;
                    @include fs(14);
                    line-height: 1.4;
                    span {
                        display: block;
                    }
                }
            }
        }
        &_form {
            .c-ttl_lp_bgGr {
                margin-bottom: 60px;
                @media #{$querySp} {
                    margin-bottom: 30px;
                }
            }
            &_head {
                width: 65.33%;
                margin: 0 auto 30px;
                @media #{$querySp} {
                    width: 100%;
                    margin-bottom: 28px;
                }
            }
            &_flow {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 48px;
                @media #{$querySp} {
                    margin-bottom: 28px;
                }
                &_arw {
                    width: 26.53%;
                    @media #{$querySp} {
                        width: 72px;
                    }
                    // @media screen and (max-width: 440px) {
                    //     width: 64px;
                    // }
                    // @media screen and (max-width: 320px) {
                    //     width: 58px;
                    // }
                    .-arw {
                        width: calc(100% - 20px);
                        height: 60px;
                        background: #acbbc3;
                        position: relative;
                        text-align: center;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        @media #{$querySp} {
                            width: 60px;
                            height: 38px;
                        }
                        // @media screen and (max-width: 440px) {
                        //     width: 56px;
                        // }
                        &::after {
                            border-left: 20px solid #acbbc3;
                            border-top: 30px solid transparent;
                            border-bottom: 30px solid transparent;
                            content: "";
                            position: absolute;
                            left: 100%;
                            top: 0;
                            @media #{$querySp} {
                                border-left: 6px solid #acbbc3;
                                border-top: 19px solid transparent;
                                border-bottom: 19px solid transparent;
                            }
                        }
                        span {
                            @include fs(18);
                            font-weight: 900;
                            color: $clrWht;
                            @media #{$querySp} {
                                @include fs(12);
                                line-height: 1.2;
                            }
                        }
                    }
                    .-fix {
                        background: #acbbc3;
                        text-align: center;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        height: 60px;
                        @media #{$querySp} {
                            height: 38px;
                        }
                        span {
                            @include fs(18);
                            font-weight: 900;
                            color: $clrWht;
                            @media #{$querySp} {
                                @include fs(12);
                                line-height: 1.2;
                            }
                        }
                    }
                }
                &_dot {
                    width: 10.2%;
                    padding: 0 18px;
                    box-sizing: border-box;
                    @media #{$querySp} {
                        width: calc((100% - 216px) / 2);
                        padding: 0 7px;
                    }
                    // @media screen and (max-width: 640px) {
                    //     width: 30px;
                    //     padding: 0 6px;
                    // }
                    img {
                        width: 100%;
                    }
                }
            }
            &_read {
                @include fs(18);
                line-height: 1.7;
            }
        }
    }
}

.p-country-name {
    display: none;
}

.p-lp_entry_form_flow_arw {
    &.-start {
        .-arw {
            background-color: $clrLpRed;
            &::after {
                border-left: 20px solid $clrLpRed;
                @media #{$querySp} {
                    border-left: 6px solid $clrLpRed;
                }
            }
        }
    }
    &.-confirm {
        .-arw {
            background-color: #acbbc3;
            &::after {
                border-left: 20px solid #acbbc3;
                @media #{$querySp} {
                    border-left: 6px solid #acbbc3;
                }
            }
        }
    }
    &.-thanks {
        .-fix {
            background-color: #acbbc3;
        }
    }
}

.p-lp_entry_form {
    .mw_wp_form {
        &_confirm {
            .p-lp_entry_form_flow_arw {
                &.-start {
                    .-arw {
                        background-color: #acbbc3;
                        &::after {
                            border-left: 20px solid #acbbc3;
                            border-top: 30px solid transparent;
                            border-bottom: 30px solid transparent;
                            @media #{$querySp} {
                                border-left: 6px solid #acbbc3;
                                border-top: 19px solid transparent;
                                border-bottom: 19px solid transparent;
                            }
                        }
                    }
                }
                &.-confirm {
                    .-arw {
                        background-color: $clrLpRed;
                        &::after {
                            border-left: 20px solid $clrLpRed;
                            @media #{$querySp} {
                                border-left: 6px solid $clrLpRed;
                            }
                        }
                    }
                }
                &.-thanks {
                    .-fix {
                        background-color: #acbbc3;
                    }
                }
            }
            .p-entry_form_row span,
            .p-entry_form_select:before,
            .p-privacy,
            .p-entry_form_agree {
                display: none;
            }
            .c-tbl_form th {
                @media #{$queryPc} {
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
            }
            .p-entry_form_select {
                vertical-align: top;
                margin-bottom: 0;
            }
        }
        &_complete {
            .p-lp_entry_form_flow_arw {
                &.-start {
                    .-arw {
                        background-color: #acbbc3;
                        &::after {
                            border-left: 20px solid #acbbc3;
                            @media #{$querySp} {
                                border-left: 6px solid #acbbc3;
                            }
                        }
                    }
                }
                &.-confirm {
                    .-arw {
                        background-color: #acbbc3;
                        &::after {
                            border-left: 20px solid #acbbc3;
                            @media #{$querySp} {
                                border-left: 6px solid #acbbc3;
                            }
                        }
                    }
                }
                &.-thanks {
                    .-fix {
                        background-color: $clrLpRed;
                    }
                }
            }
        }
    }
}