.l-lpcont {
    &_l {
        width: 100%;
        max-width: 1432px;
        padding: 0 30px;
        margin: auto;
        @media #{$querySp} {
            width: 100%;
            padding: 0 5%;
        }
    }
    &_m {
        width: 100%;
        max-width: 1260px;
        padding: 0 30px;
        margin: auto;
        @media #{$querySp} {
            width: 100%;
            padding: 0 5%;
        }
    }
}

.l-cont {
    &_l {
        width: 100%;
        max-width: 1432px;
        padding: 0 30px;
        margin: auto;
        @media #{$querySp} {
            width: 100%;
            padding: 0 5%;
        }
    }
    &_m {
        width: 100%;
        max-width: 1260px;
        padding: 0 30px;
        margin: auto;
        @media #{$querySp} {
            width: 100%;
            padding: 0 5%;
        }
    }
    &_s {
        width: 100%;
        max-width: 1110px;
        padding: 0 15px;
        margin: auto;
        @media #{$querySp} {
            width: 100%;
            padding: 0 5%;
        }
    }
    &_ss {
        width: 100%;
        max-width: 990px;
        padding: 0 15px;
        margin: auto;
        @media #{$querySp} {
            width: 100%;
            padding: 0 6.5%;
        }
    }
    &_inner {
        &_l {
            margin-bottom: 90px;
            &:last-child {
                margin-bottom: 0;
            }
            @media #{$querySp} {
                margin-bottom: 40px;
            }
        }
        &_m {
            margin-bottom: 60px;
            &:last-child {
                margin-bottom: 0;
            }
            @media #{$querySp} {
                margin-bottom: 24px;
            }
        }
        &_s {
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0;
            }
            @media #{$querySp} {
                margin-bottom: 18px;
            }
        }
    }
}

.c-bg {
    &_gry {
        background-color: #e4edf5;
    }
}