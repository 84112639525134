.c-tbl {
    &_base {
        width: 100%;
        border-top: 1px solid $clrBorder;
        th,
        td {
            padding: 16px;
            border-bottom: 1px solid $clrBorder;
            vertical-align: top;
            @media #{$querySp} {
                padding: 10px;
            }
        }
        th {
            width: 32%;
            font-weight: 500;
        }
        td {
            width: 68%;
        }
    }
    &_form {
        width: 100%;
        border-top: 1px solid #d5d8d9;
        th,
        td {
            padding: 16px;
            border-bottom: 1px solid #d5d8d9;
            vertical-align: top;
            @media #{$querySp} {
                display: block;
                width: 100%;
            }
        }
        th {
            width: 28%;
            font-weight: bold;
            padding: 42px 20px 24px;
            @media #{$querySp} {
                width: 100%;
                padding: 16px 0 8px;
                border-bottom: none;
            }
        }
        td {
            width: 72%;
            padding: 30px 20px 30px 0;
            @media #{$querySp} {
                width: 100%;
                padding: 0 0 20px;
            }
        }
    }
}