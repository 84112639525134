// ------------------------- utility - only
.u-only_sp {
    @media #{$querySp} {
        display: block;
    }
    @media #{$queryPc} {
        display: none !important;
    }
}

.u-only_pc {
    @media #{$queryPc} {
        display: block;
    }
    @media #{$querySp} {
        display: none !important;
    }
}

// ------------------------- utility - font-family
.u-ff_jp {
    font-family: $fontFamily;
}

.u-ff_en {
    font-family: $fontFamilyEn;
}

// ------------------------- utility - color
.u-clr {
    &_LpRed {
        color: $clrLpRed;
    }
}

// ------------------------- utility - background
.u-bg {
    &_blue {
        &-gr {
            background: rgb(0, 230, 255);
            background: linear-gradient(90deg, rgba(0, 230, 255, 1) 0%, rgba(100, 240, 71, 1) 100%);
        }
    }
}

// ------------------------- utility - align
.u-ta_l {
    text-align: left !important;
}

.u-ta_c {
    text-align: center !important;
}

.u-ta_r {
    text-align: right !important;
}

.u-va_t {
    vertical-align: top !important;
}

.u-va_m {
    vertical-align: middle !important;
}

.u-va_b {
    vertical-align: bottom !important;
}

// ------------------------- utility - margin
$marginStride: 100;
@while $marginStride>-1 {
    .u-mt_#{$marginStride} {
        margin-top: #{$marginStride}px !important;
    }
    .u-mb_#{$marginStride} {
        margin-bottom: #{$marginStride}px !important;
    }
    .u-mr_#{$marginStride} {
        margin-right: #{$marginStride}px !important;
    }
    .u-ml_#{$marginStride} {
        margin-left: #{$marginStride}px !important;
    }
    $marginStride: $marginStride - 5;
}

// ------------------------- utility - padding
$paddingStride: 100;
@while $paddingStride>-1 {
    .u-pt_#{$paddingStride} {
        padding-top: #{$paddingStride}px !important;
    }
    .u-pb_#{$paddingStride} {
        padding-bottom: #{$paddingStride}px !important;
    }
    .u-pr_#{$paddingStride} {
        padding-right: #{$paddingStride}px !important;
    }
    .u-pl_#{$paddingStride} {
        padding-left: #{$paddingStride}px !important;
    }
    .u-p_#{$paddingStride} {
        padding: #{$paddingStride}px !important;
    }
    $paddingStride: $paddingStride - 5;
}

// TAB
$paddingStrideTab: 100;
@while $paddingStrideTab>-1 {
    .u-pt_#{$paddingStrideTab}--tab {
        @media #{$queryTab} {
            padding-top: #{$paddingStrideTab}px !important;
        }
    }
    .u-pb_#{$paddingStrideTab}--tab {
        @media #{$queryTab} {
            padding-bottom: #{$paddingStrideTab}px !important;
        }
    }
    .u-pr_#{$paddingStrideTab}--tab {
        @media #{$queryTab} {
            padding-right: #{$paddingStrideTab}px !important;
        }
    }
    .u-pl_#{$paddingStrideTab}--tab {
        @media #{$queryTab} {
            padding-left: #{$paddingStrideTab}px !important;
        }
    }
    .u-p_#{$paddingStrideTab}--tab {
        @media #{$queryTab} {
            padding: #{$paddingStrideTab}px !important;
        }
    }
    $paddingStrideTab: $paddingStrideTab - 5;
}

// SP
$paddingStrideSp: 100;
@while $paddingStrideSp>-1 {
    .u-pt_#{$paddingStrideSp}--sp {
        @media #{$querySp} {
            padding-top: #{$paddingStrideSp}px !important;
        }
    }
    .u-pb_#{$paddingStrideSp}--sp {
        @media #{$querySp} {
            padding-bottom: #{$paddingStrideSp}px !important;
        }
    }
    .u-pr_#{$paddingStrideSp}--sp {
        @media #{$querySp} {
            padding-right: #{$paddingStrideSp}px !important;
        }
    }
    .u-pl_#{$paddingStrideSp}--sp {
        @media #{$querySp} {
            padding-left: #{$paddingStrideSp}px !important;
        }
    }
    .u-p_#{$paddingStrideSp}--sp {
        @media #{$querySp} {
            padding: #{$paddingStrideSp}px !important;
        }
    }
    $paddingStrideSp: $paddingStrideSp - 5;
}

// ------------------------- utility - border
.u-bdr_t {
    border-top: 1px $clrBorder solid;
}

.u-bdr_b {
    border-bottom: 1px $clrBorder solid;
}

.u-bdr_l {
    border-left: 1px $clrBorder solid;
}

.u-bdr_r {
    border-right: 1px $clrBorder solid;
}