.c-list {
    &_detail {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        font-weight: 900;
        line-height: 1;
        &:not(:first-child) {
            margin-top: 48px;
            @media #{$querySp} {
                margin-top: 22px;
            }
        }
        dt {
            width: 160px;
            height: 32px;
            line-height: 31px;
            text-align: center;
            background: rgb(0, 230, 255);
            background: linear-gradient(90deg, rgba(0, 230, 255, 1) 0%, rgba(100, 240, 71, 1) 100%);
            @include fs(20);
            margin-right: 48px;
            @media #{$querySp} {
                width: 170px;
                @include fs(18);
                margin-right: 0;
                margin-bottom: 12px;
            }
        }
        dd {
            .-year {
                @include fs(24);
                margin-bottom: 10px;
                @media #{$querySp} {
                    @include fs(20);
                    margin-bottom: 6px;
                }
            }
            .-date {
                @include fs(32);
                font-family: $fontFamilyEn;
                @media #{$querySp} {
                    @include fs(28);
                }
                span {
                    font-weight: 300;
                    @include fs(14);
                    @media #{$querySp} {
                        margin-top: -6px;
                        line-height: 1;
                    }
                }
            }
            .-ttl {
                @include fs(20);
                margin-bottom: 6px;
            }
            .-txt {
                font-weight: 400;
                @include fs(18);
                line-height: 1.6;
                @media #{$querySp} {
                    @include fs(16);
                }
            }
            .-fzmin {
                font-size: 32px!important;
                font-family: $fontFamily;
                font-weight: 900!important;
                @media #{$querySp} {
                    font-size: 20px!important;
                }
            }
        }
    }
    &_timeline {
        position: relative;
        &::before {
            content: "";
            display: inline-block;
            background-color: $clrLpRed;
            position: absolute;
            width: 2px;
            height: 100%;
            top: 0;
            left: 79px;
            z-index: 1;
            @media #{$querySp} {
                left: 39px;
            }
        }
        dl {
            display: flex;
            flex-wrap: wrap;
            line-height: 1;
            @include fs(20);
            margin-bottom: 40px;
            position: relative;
            z-index: 10;
            @media #{$querySp} {
                margin-bottom: 20px;
                font-size: 4.73vw;
            }
            dt {
                width: 160px;
                height: 32px;
                line-height: 31px;
                text-align: center;
                font-weight: 900;
                color: $clrWht;
                background-color: $clrLpRed;
                margin-right: 48px;
                @media #{$querySp} {
                    width: 80px;
                    margin-right: 12px;
                }
            }
            dd {
                line-height: 1.6;
                font-weight: 900;
                ul {
                    font-weight: 300;
                    @include fs(18);
                    @media #{$querySp} {
                        margin-top: 4px;
                        @include fs(16);
                    }
                }
            }
        }
    }
    &_dot {
        li {
            padding-left: 1.5em;
            position: relative;
            line-height: 1.6;
            @media #{$querySp} {
                margin-bottom: 8px;
                padding-left: 1em;
            }
            &::before {
                content: "●";
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                @media #{$querySp} {
                    font-size: 10px;
                    top: 0.4em;
                }
            }
        }
        &.-red {
            li {
                &::before {
                    color: $clrLpRed;
                }
            }
        }
    }
    // &_col {
    //     display: flex;
    //     flex-wrap: wrap;
    //     @media #{$querySp} {
    //         display: block;
    //     }
    //     &.-col3 {
    //         li {
    //             width: 31.5%;
    //             margin-right: 2.75%;
    //             margin-bottom: 40px;
    //             @media #{$querySp} {
    //                 width: 100%;
    //                 margin-bottom: 30px;
    //             }
    //             @media #{$queryPc} {
    //                 &:nth-child(3n) {
    //                     margin-right: 0;
    //                 }
    //             }
    //         }
    //     }
    //     &_img {
    //         margin-bottom: 30px;
    //         @media #{$querySp} {
    //             margin-bottom: 14px;
    //         }
    //         picture {
    //             width: 100%;
    //             img {
    //                 width: 100%;
    //             }
    //         }
    //     }
    //     &_cont {
    //         &_ttl {
    //             text-align: center;
    //         }
    //     }
    // }
    // &_check {
    //     li {
    //         position: relative;
    //         padding-left: 36px;
    //         line-height: 1.2;
    //         margin-bottom: 30px;
    //         @include fs(17);
    //         font-weight: bold;
    //         color: $clrWht;
    //         @media #{$querySp} {
    //             padding-left: 24px;
    //             @include fs(15);
    //             margin-bottom: 12px;
    //             line-height: 1.3;
    //         }
    //         &::before {
    //             content: "";
    //             display: inline-block;
    //             background-image: url(../img/icn_check.svg);
    //             background-repeat: no-repeat;
    //             background-position: center;
    //             background-size: cover;
    //             position: absolute;
    //             width: 26px;
    //             height: 26px;
    //             top: 50%;
    //             left: 0;
    //             transform: translateY(-50%);
    //             @media #{$querySp} {
    //                 width: 18px;
    //                 height: 18px;
    //                 // top: 2px;
    //                 // transform: none;
    //             }
    //         }
    //     }
    // }
}