.l-footer {
    background-color: $clrBg;
    padding: 133px 0 0;
    position: relative;
    @media #{$querySp} {
        padding: 90px 0 0;
    }
    &_logo {
        position: absolute;
        top: -7px;
        left: 50%;
        transform: translateX(-50%);
        width: 400px;
        height: 140px;
        background-color: $clrWht;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-radius: 0 0 15px 15px;
        @media #{$querySp} {
            width: 260px;
            height: 90px;
            top: 0;
        }
        a {
            display: block;
            width: 64%;
            @media #{$querySp} {
                width: 166px;
            }
        }
    }
    .l-cont_l {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        color: $clrWht;
        @media #{$querySp} {
            display: block;
        }
        a {
            color: $clrWht;
        }
        &>div {
            width: 332px;
            padding: 32px 26px 0;
            @media #{$querySp} {
                width: auto;
                padding: 22px 0 20px;
            }
        }
    }
    &_contact {
        text-align: right;
        @media #{$querySp} {
            text-align: center;
        }
        &_inner {
            display: inline-block;
            text-align: left;
            font-weight: 900;
            @media #{$querySp} {
                text-align: center;
            }
        }
        .-jp {
            @include fs(30);
            line-height: 1;
            color: $clrLpRed;
            margin-bottom: 8px;
            display: block;
            @media #{$querySp} {
                @include fs(26);
                margin-bottom: 6px;
            }
        }
        .-en {
            @include fs(18);
            font-family: $fontFamilyEn;
            @media #{$querySp} {
                @include fs(14);
            }
        }
    }
    &_address {
        border-left: 1px solid $clrWht;
        @media #{$querySp} {
            text-align: center;
            border: none;
            border-top: 1px solid $clrWht;
        }
        &_inner {
            @media #{$querySp} {
                text-align: left;
                display: inline-block;
            }
        }
        .-ttl {
            @include fs(24);
            font-weight: 900;
            margin-bottom: 8px;
            @media #{$querySp} {
                @include fs(20);
                margin-bottom: 6px;
            }
        }
        .-add {
            p {
                line-height: 1.5;
                &:first-child {
                    margin-bottom: 10px;
                    @media #{$querySp} {
                        margin-bottom: 6px;
                    }
                }
                @media #{$querySp} {
                    @include fs(16);
                }
            }
        }
    }
    &_copy {
        color: $clrLpBase;
        text-align: center;
        @include fs(10);
        padding: 72px 0 26px;
        @media #{$querySp} {
            @include fs(10);
            padding: 25px 0 26px;
        }
    }
}