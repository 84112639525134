.c-key {}

.p-index {
    &_news {
        color: $clrWht;
        &_cont {
            &_inner {
                // height: 380px;
                // overflow-y: scroll;
                height: auto;
                max-height: 380px;
                overflow-y: auto;
                @media #{$querySp} {
                    max-height: 300px;
                }
                &::-webkit-scrollbar {
                    width: 8px;
                    border-radius: 4px;
                    @media #{$querySp} {
                        width: 6px;
                        border-radius: 3px;
                    }
                }
                &::-webkit-scrollbar-track {
                    background-color: #555;
                    border-radius: 4px;
                    @media #{$querySp} {
                        border-radius: 3px;
                    }
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $clrLpMain;
                    border-radius: 4px;
                    @media #{$querySp} {
                        border-radius: 3px;
                    }
                }
                ul {
                    padding-right: 36px;
                    @media #{$querySp} {
                        padding-right: 16px;
                    }
                    li {
                        padding: 16px 24px 18px;
                        border-bottom: 1px solid $clrBorder;
                        // display: flex;
                        // flex-wrap: wrap;
                        @media #{$querySp} {
                            padding: 10px 10px 12px;
                        }
                        .-time {
                            font-family: $fontFamilyEn;
                            @include fs(18);
                            margin-bottom: 0.3em;
                            @media #{$querySp} {
                                @include fs(16);
                            }
                        }
                    }
                }
            }
        }
    }
    &_about {
        &_inner {
            display: flex;
            justify-content: space-between;
            @media #{$querySp} {
                display: block;
            }
        }
        &_ttl {
            .-ttl_img {
                max-width: 370px;
                width: 25.6944vw;
                margin-bottom: 20px;
                margin-left: -40px;
                @media #{$querySp} {
                    width: 100%;
                    margin-bottom: 15px;
                    margin-left: 0;
                    img {
                        width: 80%;
                    }
                }
            }
            .-ttl_txt {
                color: $clrLpMain;
                @include fs(20);
                @media #{$querySp} {
                    margin-bottom: 20px;
                    @media #{$querySp} {
                        @include fs(18);
                    }
                }
            }
        }
        &_txt {
            width: 54.1667%;
            @media #{$querySp} {
                width: 100%;
                margin-top: 32px;
            }
            p {
                line-height: 2.2;
            }
            .c-copy_l {
                line-height: 1.3;
            }
            .c-btn_base {
                min-width: 280px;
                margin-top: 35px;
                @media #{$querySp} {
                    display: block;
                    min-width: unset;
                    width: 80%;
                    height: 43px;
                    margin: 24px auto 0;
                }
            }
        }
    }
    &_service {
        .l-cont_l {
            position: relative;
            &::after {
                content: "";
                display: inline-block;
                background-image: url(../img/ttl_service.svg);
                background-repeat: no-repeat;
                background-position: top;
                background-size: contain;
                position: absolute;
                width: 13.75vw;
                max-width: 198px;
                height: 100%;
                top: -42px;
                right: -80px;
                z-index: 1;
                @media screen and (min-width: 767px) and (max-width: 1410px) {
                    right: 0;
                }
                @media #{$querySp} {
                    width: 34vw;
                    max-width: unset;
                    height: 100%;
                    top: -12vw;
                    right: 2%
                }
            }
        }
        &_inner {
            position: relative;
            z-index: 10;
        }
        &_point {
            padding-bottom: 20px;
            border-bottom: 1px solid $clrBorder;
            @media #{$querySp} {
                padding-bottom: 10px;
            }
        }
        .c-list_col {
            margin-top: 45px;
            @media #{$querySp} {
                margin-top: 30px;
            }
            &_cont {
                &_ttl {
                    .-point {
                        width: 84px;
                        display: block;
                        margin: auto;
                        margin-bottom: 6px;
                        @media #{$querySp} {
                            width: 64px;
                            margin-bottom: 4px;
                        }
                    }
                    .c-ttl_col {
                        @include fs(24);
                        font-weight: bold;
                        margin-bottom: 22px;
                        letter-spacing: -0.01em;
                        @media #{$querySp} {
                            @include fs(18);
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        &_flow {
            padding-top: 56px;
            @media #{$querySp} {
                padding-top: 40px;
            }
            &_list {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                @media #{$queryPc} {
                    justify-content: space-between;
                    margin-bottom: 80px;
                }
                @media #{$querySp} {
                    justify-content: center;
                    margin-bottom: 48px;
                }
                &::after {
                    @media #{$queryPc} {
                        content: "";
                        display: inline-block;
                        background-color: $clrLBlue;
                        position: absolute;
                        width: 90%;
                        height: 4px;
                        top: 41%;
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: 1;
                    }
                }
                li {
                    width: 14.45%;
                    position: relative;
                    z-index: 10;
                    @media #{$querySp} {
                        margin-bottom: 16px;
                    }
                    .-img {
                        position: relative;
                        z-index: 10;
                        img {
                            width: 100%;
                        }
                    }
                    &::after {
                        @media #{$querySp} {
                            content: "";
                            display: inline-block;
                            background-color: $clrLBlue;
                            position: absolute;
                            width: 100%;
                            height: 4px;
                            top: 41%;
                            left: 5vw;
                            z-index: 1;
                        }
                    }
                    &:nth-child(4) {
                        &::after {
                            @media #{$querySp} {
                                left: unset;
                                right: -10vw;
                                width: 100vw;
                            }
                        }
                    }
                    &:nth-child(5) {
                        &::after {
                            @media #{$querySp} {
                                content: none;
                            }
                        }
                    }
                    @media #{$querySp} {
                        width: 31%;
                        margin-right: 3.5%;
                        &:nth-child(3n) {
                            margin-right: 0;
                        }
                    }
                    .-ttl {
                        @include fs(17);
                        font-weight: bold;
                        margin-top: 10px;
                        text-align: center;
                        @media screen and (max-width: 1160px) and (min-width: 768px) {
                            font-size: 1.4vw;
                        }
                        @media #{$querySp} {
                            margin-top: 8px;
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }
}

.form {
    background-color: $clrBg02;
    &_cont {
        &_note {
            margin-top: 26px;
        }
        &_btn {
            margin-top: 28px;
            text-align: center;
            input[type=button],
            input[type=submit],
            a {
                display: block;
                width: 28.6%;
                max-width: 240px;
                margin: auto;
                border: 1px solid $clrLpMain;
                color: $clrWht;
                background-color: $clrLpMain;
                padding: 10px 20px;
                font-weight: 600;
                @include fs(16);
                cursor: pointer;
                transition: all .4s;
                @media #{$querySp} {
                    max-width: unset;
                    width: 80%;
                    padding: 10px 16px;
                    @include fs(14);
                }
                &:hover {
                    color: $clrLpMain;
                    background-color: $clrWht;
                    transition: all .4s;
                }
            }
        }
    }
}

.faq {
    color: $clrWht;
    .c-ttl_lead {
        color: $clrWht;
    }
    &_list {
        .-item {
            padding-bottom: 24px;
            margin-bottom: 26px;
            border-bottom: 1px solid $clrBorder;
            @media #{$querySp} {
                padding-bottom: 18px;
                margin-bottom: 20px;
            }
            .-ttl {
                // font-family: $fontFamilyMin;
                @include fs(20);
                padding-left: 48px;
                position: relative;
                margin-bottom: 16px;
                font-weight: 600;
                @media #{$querySp} {
                    @include fs(16);
                    padding-left: 32px;
                    margin-bottom: 12px;
                }
                &::before {
                    content: "Q";
                    @include fs(32);
                    display: inline-block;
                    position: absolute;
                    top: -10px;
                    left: 0;
                    font-family: $fontFamilyEn;
                    font-weight: 600;
                    @media #{$querySp} {
                        @include fs(28);
                    }
                }
            }
            .-txt {
                margin-left: 48px;
                padding-left: 48px;
                position: relative;
                @media #{$querySp} {
                    padding-left: 32px;
                    margin-left: 24px;
                }
                &::before {
                    content: "A";
                    @include fs(32);
                    display: inline-block;
                    position: absolute;
                    top: -10px;
                    left: 0;
                    font-family: $fontFamilyEn;
                    font-weight: 600;
                    @media #{$querySp} {
                        @include fs(28);
                    }
                }
            }
        }
    }
}