[class^="c-ttl"] {
    font-weight: 900;
}

.c-ttl {
    &_lp {
        &_red {
            &-jp {
                font-weight: 900;
                color: $clrLpRed;
                @include fs(58);
                display: inline-block;
                line-height: 1;
                position: relative;
                padding: 0 40px;
                @media #{$querySp} {
                    font-size: 8.94vw;
                    padding: 0 25px;
                }
                &::before {
                    content: "";
                    display: block;
                    background-color: $clrLpRed;
                    width: 23px;
                    height: 2px;
                    position: absolute;
                    top: 32px;
                    left: 0;
                    @media #{$querySp} {
                        width: 13px;
                        height: 1px;
                        top: 19px;
                    }
                }
                &::after {
                    content: "";
                    display: block;
                    background-color: $clrLpRed;
                    width: 23px;
                    height: 2px;
                    position: absolute;
                    top: 32px;
                    right: 0;
                    @media #{$querySp} {
                        width: 13px;
                        height: 1px;
                        top: 19px;
                    }
                }
            }
            &-en {
                @include fs(20);
                font-weight: 900;
                font-family: $fontFamilyEn;
                line-height: 1;
                display: block;
                margin-top: 14px;
                @media #{$querySp} {
                    @include fs(12);
                    margin-top: 8px;
                }
            }
        }
        &_bgGr {
            text-align: center;
            margin-bottom: 25px;
            @media #{$querySp} {
                margin-bottom: 20px;
            }
            span {
                display: inline-block;
                line-height: 1.4;
                @include fs(32);
                padding: 0 13px 3px;
                background: #00e6ff;
                background: linear-gradient(90deg, #00e6ff 0, #64f047 100%);
                @media #{$querySp} {
                    font-size: 6.315vw;
                }
            }
        }
    }
    &_lead {
        margin-bottom: 34px;
        text-align: center;
        @media #{$querySp} {
            margin-bottom: 20px;
        }
    }
    &_base {
        margin-bottom: 32px;
        span {
            display: block;
        }
        .-jp {
            margin-bottom: 8px;
            line-height: 1.3;
        }
        .-en {
            color: $clrLpMain;
            font-family: $fontFamilyEn;
            @include fs(18);
            line-height: 1.2;
            font-weight: 600;
        }
        &.-center {
            text-align: center;
        }
        &.-wht {
            .-jp {
                color: $clrWht;
            }
        }
    }
    &_headLine {
        line-height: 1.5;
        font-weight: 600;
        position: relative;
        padding-left: 45px;
        @include fs(26);
        margin-bottom: 32px;
        &::before {
            content: "";
            display: inline-block;
            background: rgb(34, 190, 190);
            background: linear-gradient(90deg, rgba(34, 190, 190, 1) 0%, rgba(0, 123, 188, 1) 100%);
            position: absolute;
            width: 32px;
            height: 3px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        @media #{$querySp} {
            @include fs(20);
            margin-bottom: 20px;
            padding-left: 32px;
            &::before {
                width: 18px;
            }
        }
    }
    &_underLine {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        line-height: 1.2;
        padding-bottom: 24px;
        margin-bottom: 48px;
        position: relative;
        @media #{$querySp} {
            padding-bottom: 16px;
            margin-bottom: 30px;
        }
        .-main {
            font-family: $fontFamilyEn;
            font-weight: bold;
            @include fs(56);
            letter-spacing: .05em;
            @media #{$querySp} {
                @include fs(35);
            }
        }
        .-sub {
            font-weight: bold;
            @include fs(24);
            margin-left: 32px;
            @media #{$querySp} {
                @include fs(16);
                margin-left: 16px;
            }
        }
        &::before {
            content: "";
            display: inline-block;
            background: rgb(34, 190, 190);
            background: linear-gradient(90deg, rgba(34, 190, 190, 1) 0%, rgba(0, 123, 188, 1) 100%);
            position: absolute;
            width: 80px;
            height: 3px;
            bottom: 0;
            left: 0;
            @media #{$querySp} {
                width: 48px;
                height: 2px;
            }
        }
    }
    &_page {
        padding-bottom: 32px;
        margin-bottom: 40px;
        position: relative;
        text-align: center;
        font-weight: bold;
        @media #{$querySp} {
            padding-bottom: 16px;
            margin-bottom: 22px;
        }
        &::after {
            content: "";
            display: inline-block;
            background: rgb(34, 190, 190);
            background: linear-gradient(135deg, rgba(34, 190, 190, 1) 0%, rgba(0, 123, 188, 1) 100%);
            position: absolute;
            width: 80px;
            height: 4px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            @media #{$querySp} {
                width: 48px;
                height: 3px;
            }
        }
        .-en {
            display: block;
            @include fs(58);
            line-height: 1;
            margin-bottom: 20px;
            font-family: $fontFamilyEn;
            @media #{$querySp} {
                @include fs(32);
                margin-bottom: 12px;
            }
        }
        .-jp {
            display: block;
            @include fs(25);
            line-height: 1.4;
            letter-spacing: -0.05em;
            @media #{$querySp} {
                @include fs(16);
            }
        }
        &_min {
            text-align: left;
            @include fs(26);
            margin-bottom: 1em;
            line-height: 1.4;
            letter-spacing: -0.05em;
            @media #{$querySp} {
                @include fs(18);
            }
        }
        &_s {
            text-align: left;
            @include fs(24);
            margin-bottom: .75em;
            line-height: 1.4;
            letter-spacing: -0.05em;
            @media #{$querySp} {
                @include fs(16);
            }
        }
        &_spl {
            text-align: left;
            @include fs(32);
            margin-bottom: 48px;
            line-height: 1.4;
            letter-spacing: -0.05em;
            text-align: center;
            @media #{$querySp} {
                @include fs(20);
                margin-bottom: 30px;
            }
        }
    }
}

.c-copy {
    &_l {
        font-weight: bold;
        @include fs(32);
        line-height: 1.3;
        margin-bottom: 24px;
        @media #{$querySp} {
            @include fs(20);
            margin-bottom: 16px;
        }
    }
}

.-clrBlue {
    color: $clrLpMain;
}