.c-cv {
    margin-top: 110px;
    background: rgb(0, 123, 188);
    background: linear-gradient(135deg, rgba(0, 123, 188, 1) 0%, rgba(34, 190, 190, 1) 100%);
    padding-top: 0;
    padding-bottom: 0;
    @media #{$querySp} {
        margin-top: 56px;
    }
    .l-cont_s {
        position: relative;
        width: 100%;
        height: 100%;
        color: $clrWht;
        position: relative;
        padding: 110px 0 75px;
        @media #{$querySp} {
            padding: 56px 5% 48px;
        }
    }
    &_ttlImg {
        width: 46.875vw;
        max-width: 675px;
        position: absolute;
        top: -46px;
        left: 20px;
        z-index: 1;
        @media #{$querySp} {
            width: 85vw;
            top: -40px;
            left: 3%;
        }
    }
    &_ttl {
        @include fs(32);
        text-align: center;
        font-weight: bold;
        line-height: 1.3;
        padding-bottom: 22px;
        margin-bottom: 46px;
        position: relative;
        z-index: 10;
        @media #{$querySp} {
            font-size: 5.4vw;
            padding-bottom: 18px;
            margin-bottom: 30px;
        }
        &::after {
            content: "";
            display: inline-block;
            background-color: $clrWht;
            position: absolute;
            border-radius: 2px;
            width: 80px;
            height: 4px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            @media #{$querySp} {
                width: 48px;
                height: 3px;
            }
        }
    }
    &_inner {
        &_cont {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin-bottom: 42px;
            @media #{$querySp} {
                display: block;
                margin-bottom: 20px;
            }
            &_item {
                width: 50%;
                padding: 10px 5% 15px;
                text-align: center;
                @media #{$querySp} {
                    width: 100%;
                    padding: 20px 2% 24px;
                    &:first-child {
                        padding-top: 0;
                    }
                }
                &:first-child {
                    @media #{$queryPc} {
                        border-right: 1px solid $clrWht;
                    }
                    @media #{$querySp} {
                        border-bottom: 1px solid $clrWht;
                    }
                }
                .-item {
                    &_ttl {
                        @include fs(24);
                        font-weight: bold;
                        margin-bottom: 10px;
                        @media #{$querySp} {
                            @include fs(18);
                            margin-bottom: 6px;
                        }
                    }
                    &_txt {
                        margin-bottom: 30px;
                        @media #{$querySp} {
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }
    &_faqLink {
        text-align: center;
        a {
            color: $clrWht;
            text-decoration: underline;
            transition: all .4s;
            &:hover {
                text-decoration: none;
                transition: all .4s;
            }
        }
    }
}