// ------------------------- base
html {
    font-size: 62.5%;
    width: 100%;
    height: 100%;
    -webkit-text-size-adjust: none;
    -webkit-print-color-adjust: exact;
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: $clrBorder;
        border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(0, 123, 188);
        background: linear-gradient(0deg, rgba(0, 123, 188, 1) 0%, rgba(34, 190, 190, 1) 100%);
        border-radius: 3px;
    }
    & {
        scrollbar-width: thin;
        scrollbar-color: $clrLpMain $clrBorder;
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    width: 100%;
    height: 100%;
    // min-width: $widthBase;
    @include fs(16);
    font-family: $fontFamily;
    font-weight: normal;
    line-height: $lhBase;
    color: $clrLpBase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "palt";
    // overflow-x: hidden;
    @media #{$querySp} {
        @include fs(14);
        line-height: $lhBaseSp;
        min-width: inherit;
    }
}

img {
    border-style: none;
    display: block;
    vertical-align: bottom;
    @media #{$querySp} {
        max-width: 100%;
    }
}

a,
a img,
button {
    transition: .4s;
}

a img:hover {
    opacity: .7;
}

a:link {
    color: $clrLpLink;
    text-decoration: none;
}

a:visited {
    color: $clrLpLink;
}

a:hover {
    color: $clrLpLinkHover;
    opacity: .8;
}

a:active {
    color: $clrLpLinkHover;
}

.fade,
.c-key_txt_main,
.c-key_txt_sub,
.c-key_inner_img {
    opacity: 0;
    transition: all 2s;
}

.fade-on {
    opacity: 1;
}