.l-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    padding: 0 0 0 30px;
    height: 90px;
    transition: all .4s;
    display: flex;
    align-items: center;
    @media #{$querySp} {
        padding: 0;
        height: 60px;
        background-color: $clrWht;
    }
    &_inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @media #{$querySp} {
            padding-left: 5%;
            padding-right: 0;
        }
    }
    &_logo {
        width: 290px;
        @media #{$querySp} {
            width: 48.42vw;
        }
        a {
            display: block;
            color: $clrTtl;
            img {
                width: 100%;
            }
        }
        &-clr {
            display: none;
            @media #{$querySp} {
                display: block;
            }
        }
        &-wht {
            display: block;
            @media #{$querySp} {
                display: none;
            }
        }
    }
    &_left {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
    }
    // .c-btn_cv {
    //     min-width: 280px;
    //     height: 80px;
    //     opacity: 0;
    //     transition: all .4s;
    //     @media #{$querySp} {
    //         height: 60px;
    //     }
    // }
    &_entry {
        height: 90px;
        transition: all .4s;
        @media #{$querySp} {
            height: 60px;
        }
        &:hover {
            transition: all .4s;
            // background: linear-gradient(90deg, rgba(0, 230, 255, 1) 33%, rgba(100, 240, 71, 1) 133%);
            &>a {
                opacity: 1;
                &::before {
                    transform-origin: left top;
                    transform: scale(1, 1);
                }
            }
        }
        &>a {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            height: 100%;
            padding: 0 4.5313vw;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(90deg, rgba(0, 230, 255, 1) 33%, rgba(100, 240, 71, 1) 133%);
                width: 100%;
                height: 100%;
                transform: scale(0, 1);
                transform-origin: right top;
                transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
            }
            @media screen and (min-width: 1921px) {
                padding: 0 87px;
            }
            @media #{$querySp} {
                padding: 0 6px;
            }
        }
        &_txt {
            text-align: center;
            position: relative;
            z-index: 2;
            span {
                display: block;
                line-height: 1;
            }
            .u-ff {
                &_en {
                    color: $clrLpRed;
                    @include fs(32);
                    line-height: 1;
                    @media #{$querySp} {
                        @include fs(20);
                    }
                }
                &_jp {
                    font-weight: 900;
                    @include fs(16);
                    @media #{$querySp} {
                        @include fs(10);
                        display: block;
                        margin-top: 2px;
                    }
                }
            }
        }
    }
    &.scroll {
        background-color: $clrWht;
        padding-top: 10px;
        padding-bottom: 10px;
        transition: all .4s;
        @media #{$querySp} {
            padding: 0;
            .l-header_cv_sp {
                display: block;
            }
        }
        .l-header {
            &_logo {
                &-clr {
                    display: block;
                }
                &-wht {
                    display: none;
                }
            }
        }
        .c-btn_cv {
            opacity: 1;
            transition: all .4s;
        }
    }
}

.page {
    .l-header {
        .c-btn_cv.-org {
            opacity: 1;
        }
    }
}