.p-service {
    &_trouble {
        padding-bottom: 130px;
        background: rgb(34, 190, 190);
        background: linear-gradient(135deg, rgba(34, 190, 190, 1) 0%, rgba(0, 123, 188, 1) 100%);
        position: relative;
        z-index: 1;
        @media #{$querySp} {
            padding-bottom: 60px;
        }
        &_list {
            .c-list_check {
                @media #{$queryPc} {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    li {
                        &:nth-last-child(odd) {
                            width: 52.08%;
                        }
                        &:nth-last-child(even) {
                            width: 45.83%;
                        }
                    }
                }
            }
        }
    }
    &_support {
        background-color: $clrWht;
        position: relative;
        z-index: 100;
        padding: 50px 0 16px;
        @media #{$querySp} {
            padding: 32px 0 16px;
        }
        &_ttl {
            text-align: center;
            font-weight: bold;
            color: $clrLpMain;
            @include fs(32);
            margin-bottom: 50px;
            @media #{$querySp} {
                @include fs(20);
                margin-bottom: 32px;
            }
            span {
                display: inline-block;
                position: relative;
                z-index: 10;
                background: -webkit-gradient(linear, left top, left bottom, color-stop(70%, transparent), color-stop(0%, $clrYlw));
                background: linear-gradient(transparent 70%, $clrYlw 0%);
                display: inline;
                // &::after {
                //     content: "";
                //     display: inline-block;
                //     background: $clrYlw;
                //     position: absolute;
                //     width: 100%;
                //     height: 10px;
                //     bottom: 5px;
                //     left: 50%;
                //     transform: translateX(-50%);
                //     z-index: -1;
                //     @media #{$querySp} {
                //         width: 100%;
                //         height: 6px;
                //         bottom: 3px;
                //     }
                // }
            }
        }
        &_copy {
            @include fs(24);
            font-weight: 500;
            text-align: center;
            color: $clrTtl;
            @media #{$querySp} {
                @include fs(18);
            }
        }
    }
    &_point {
        &_copy {
            margin-bottom: 64px;
            @media #{$querySp} {
                margin-bottom: 40px;
            }
        }
        &_list {
            li {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 70px;
                &:nth-child(2n) {
                    flex-direction: row-reverse;
                }
                @media #{$querySp} {
                    margin-bottom: 40px;
                    display: block;
                    position: relative;
                }
            }
            .c-list {
                &_col {
                    &_img {
                        width: 44.44%;
                        margin-bottom: 0;
                        @media #{$querySp} {
                            width: 100%;
                            margin-bottom: 1em;
                        }
                    }
                    &_cont {
                        width: 50.4%;
                        text-align: left;
                        @media #{$querySp} {
                            width: 100%;
                        }
                        &_ttl {
                            // .c-ttl_page_min {
                            //     text-align: left;
                            //     @include fs(26);
                            //     margin-bottom: 1em;
                            //     line-height: 1.3;
                            //     @media #{$querySp} {
                            //         @include fs(18);
                            //     }
                            // }
                            .-point {
                                width: 160px;
                                margin-bottom: 16px;
                                @media #{$querySp} {
                                    width: 100px;
                                    position: absolute;
                                    top: -20px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &_flow {
        &_list {
            @media #{$queryPc} {
                width: 80%;
                max-width: 880px;
                margin: auto;
            }
            @media #{$querySp} {
                .c-btn_cv {
                    width: 100%;
                    @include fs(13);
                }
            }
            li {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                position: relative;
                margin-bottom: 48px;
                @media #{$querySp} {
                    margin-bottom: 30px;
                }
                &::before {
                    content: "";
                    display: inline-block;
                    background-color: #e4edf5;
                    position: absolute;
                    width: 4px;
                    height: calc(100% + 48px);
                    top: 5%;
                    left: 58px;
                    z-index: 1;
                    @media #{$querySp} {
                        height: calc(100% + 30px);
                        width: 3px;
                        left: 29.5px;
                        top: 0;
                    }
                }
                &:last-child {
                    &::before {
                        content: none;
                    }
                }
            }
            &_img {
                width: 120px;
                position: relative;
                z-index: 10;
                @media #{$querySp} {
                    width: 80px;
                    margin-left: -10px;
                    margin-top: -25px;
                }
                img {
                    width: 100%;
                }
            }
            &_cont {
                width: calc(100% - 180px);
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                @media #{$querySp} {
                    display: block;
                    width: calc(100% - 90px);
                }
                .-cont {
                    &_txt {
                        // width: 67%;
                        width: 100%;
                        @media #{$querySp} {
                            width: 100%;
                        }
                        .-ttl {
                            margin-bottom: 0.5em;
                        }
                        .c-btn_cv {
                            margin-top: 20px;
                            @media #{$querySp} {
                                margin-top: 14px;
                            }
                        }
                    }
                    &_img {
                        width: 30%;
                        margin-top: 1em;
                        @media #{$querySp} {
                            width: 100%;
                        }
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &_faq {
        padding-bottom: 100px;
        @media #{$querySp} {
            padding-bottom: 48px;
        }
        &_list {
            &_item {
                background-color: $clrWht;
                border-radius: 16px;
                padding: 32px;
                margin-bottom: 20px;
                @media #{$querySp} {
                    border-radius: 8px;
                    padding: 20px 16px;
                    margin-bottom: 16px;
                }
                .-q {
                    padding-left: 50px;
                    position: relative;
                    @include fs(18);
                    font-weight: bold;
                    line-height: 1.4;
                    padding-bottom: 16px;
                    margin-bottom: 16px;
                    border-bottom: 1px solid $clrBorder;
                    @media #{$querySp} {
                        @include fs(14);
                        padding-left: 30px;
                    }
                    &::before {
                        content: "Q";
                        @include fs(48);
                        position: absolute;
                        top: -15px;
                        left: 0;
                        font-weight: bold;
                        color: $clrLpMain;
                        line-height: 1;
                        font-family: $fontFamilyEn;
                        @media #{$querySp} {
                            @include fs(26);
                            top: -4px;
                        }
                    }
                }
                .-a {
                    padding-left: 70px;
                    position: relative;
                    line-height: 1.5;
                    @media #{$querySp} {
                        padding-left: 45px;
                    }
                    &::before {
                        content: "A";
                        @include fs(48);
                        position: absolute;
                        top: -15px;
                        left: 20px;
                        font-weight: bold;
                        line-height: 1;
                        color: $clrGry;
                        font-family: $fontFamilyEn;
                        @media #{$querySp} {
                            left: 15px;
                            @include fs(26);
                            top: -4px;
                        }
                    }
                }
            }
        }
    }
}

.c-sec {
    &_nami {
        position: relative;
        z-index: 100;
        &::before {
            content: "";
            display: inline-block;
            background-image: url(../img/nami_top.svg);
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: 100% auto;
            position: absolute;
            width: 100%;
            height: 70%;
            min-height: 90px;
            bottom: calc(100% - 1px);
            left: 0;
            z-index: 10;
        }
        &::after {
            content: "";
            display: inline-block;
            background-image: url(../img/nami_bottom.svg);
            background-repeat: no-repeat;
            background-position: top;
            background-size: 100% auto;
            position: absolute;
            width: 100%;
            height: 60%;
            min-height: 140px;
            top: 100%;
            left: 0;
            z-index: 10;
        }
        &+section {
            padding-top: 260px;
            @media #{$querySp} {
                padding-top: 90px;
            }
        }
    }
    &_copy {
        margin-bottom: 64px;
        text-align: center;
        @media #{$querySp} {
            width: 96%;
            margin: auto;
            margin-bottom: 40px;
            text-align: left;
        }
    }
}

.c-ttl {
    &_trouble {
        color: $clrWht;
        text-align: center;
        margin-bottom: 64px;
        @media #{$querySp} {
            margin-bottom: 40px;
        }
        .-en {
            display: block;
            margin-bottom: 18px;
            @media #{$querySp} {
                width: 56vw;
                margin: auto;
                margin-bottom: 5px;
            }
            img {
                display: inline-block;
            }
        }
        .-main {
            @include fs(32);
            display: inline-block;
            position: relative;
            letter-spacing: -0.05em;
            line-height: 1.5;
            @media #{$querySp} {
                @include fs(20);
            }
            .-wave {
                display: inline-block;
                position: absolute;
                bottom: -10px;
                left: 21.5%;
                @media #{$querySp} {
                    width: 55px;
                    bottom: -6px;
                }
            }
        }
    }
}

.service {
    .c-cv {
        margin-top: 0;
    }
}