.p-philosophy {
    &_philosophy {
        &_copy {
            .-ttl {
                text-align: center;
                font-weight: bold;
                color: $clrLpMain;
                // @include fs(32);
                @include fs(26);
                margin-bottom: 48px;
                @media #{$querySp} {
                    @include fs(20);
                    margin-bottom: 24px;
                }
                span {
                    display: inline-block;
                    position: relative;
                }
            }
            .-txt {
                width: 640px;
                margin: auto;
                font-weight: 500;
                @include fs(18);
                padding: 16px 32px 26px;
                border-radius: 8px;
                border: 1px solid #ccc;
                @media #{$querySp} {
                    width: 100%;
                    @include fs(16);
                    padding: 16px 20px 26px;
                }
                &_head {
                    @include fs(24);
                    font-weight: bold;
                    text-align: center;
                    padding-bottom: 16px;
                    border-bottom: 1px solid #ccc;
                    margin-bottom: 20px;
                    font-family: $fontFamilyEn;
                    @media #{$querySp} {
                        @include fs(20);
                    }
                }
                &_list {
                    li {
                        margin-top: 8px;
                        span {
                            font-weight: bold;
                            font-family: $fontFamilyEn;
                        }
                    }
                }
            }
        }
    }
    &_message {
        padding-bottom: 100px;
        @media #{$querySp} {
            padding-bottom: 40px;
        }
        &_cont {
            @media #{$queryPc} {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                flex-direction: row-reverse;
            }
            .-img {
                width: 32%;
                @media #{$querySp} {
                    width: 72%;
                    margin: 0 auto 20px;
                }
                img {
                    width: 100%;
                }
            }
            .-txt {
                width: 64%;
                @media #{$querySp} {
                    width: 100%;
                }
                .-copy {
                    @include fs(22);
                    margin-bottom: 1em;
                    line-height: 1.4;
                    letter-spacing: -0.05em;
                    color: $clrTtl;
                    font-weight: bold;
                    @media #{$querySp} {
                        @include fs(18);
                    }
                }
                p {
                    margin-bottom: 1em;
                }
            }
        }
    }
    &_company {
        padding-bottom: 100px;
        @media #{$querySp} {
            padding-bottom: 48px;
        }
    }
}

.c-sec {
    &_nami_btm {
        position: relative;
        z-index: 100;
        &::after {
            content: "";
            display: inline-block;
            background-image: url(../img/nami_bottom.svg);
            background-repeat: no-repeat;
            background-position: top;
            background-size: 100% auto;
            position: absolute;
            width: 100%;
            height: 60%;
            min-height: 140px;
            top: 100%;
            left: 0;
            z-index: 10;
        }
        &+section {
            padding-top: 260px;
            @media #{$querySp} {
                padding-top: 90px;
            }
        }
    }
    &_copy {
        margin-bottom: 64px;
        text-align: center;
        @media #{$querySp} {
            width: 96%;
            margin: auto;
            margin-bottom: 40px;
            text-align: left;
        }
    }
}

.c-ttl {
    &_philosophy {
        text-align: center;
        margin-bottom: 64px;
        @media #{$querySp} {
            margin-bottom: 40px;
        }
        .-en {
            display: block;
            margin-bottom: 18px;
            height: 120px;
            img {
                height: 100%;
                width: auto;
            }
            @media #{$querySp} {
                width: 56vw;
                margin: auto;
                margin-bottom: 5px;
                height: auto;
            }
            img {
                display: inline-block;
            }
        }
        .-main {
            @include fs(32);
            display: inline-block;
            position: relative;
            letter-spacing: -0.05em;
            line-height: 1.5;
            @media #{$querySp} {
                @include fs(20);
            }
        }
    }
}