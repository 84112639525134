.p-terms {
    &_head {
        padding-bottom: 100px;
        @media #{$querySp} {
            padding-bottom: 48px;
        }
        .c-ttl_page {
            .-jp {
                @include fs(36);
                @media #{$querySp} {
                    @include fs(22);
                }
            }
        }
    }
    &_cont {
        &_inner {
            &_block {
                margin-bottom: 32px;
                @media #{$querySp} {
                    margin-bottom: 20px;
                }
                .-block {
                    margin-bottom: 16px;
                    @media #{$querySp} {
                        margin-bottom: 10px;
                    }
                    p {
                        margin-top: 8px;
                    }
                    ul {
                        margin-top: 8px;
                        margin-left: 20px;
                    }
                }
                .-ttl {
                    @include fs(20);
                    font-weight: bold;
                    margin-bottom: 10px;
                    @media #{$querySp} {
                        @include fs(16);
                    }
                    &_sub {
                        @include fs(18);
                        font-weight: 500;
                        @media #{$querySp} {
                            @include fs(14);
                        }
                    }
                }
            }
        }
    }
}