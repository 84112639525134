.c-box {
    &_row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &.-rev {
            flex-direction: row-reverse;
        }
        @media #{$querySp} {
            display: block;
        }
        .-txt {
            width: 62%;
            @media #{$querySp} {
                width: 100%;
            }
        }
        .-img {
            width: 34.25%;
            img {
                width: 100%;
            }
            @media #{$querySp} {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}

.c-tbl {
    width: 100%;
    border-top: 1px solid #ccc;
    tr {
        th,
        td {
            padding: 18px 30px;
            border-bottom: 1px solid #ccc;
            vertical-align: top;
            @media #{$querySp} {
                display: block;
                padding: 12px 16px;
                width: 100%;
            }
        }
        th {
            width: 28%;
            background-color: #ddd;
            @media #{$querySp} {
                width: 100%;
            }
            p {
                @include fs(16);
                line-height: 1.3;
                // font-family: $fontFamilyMin;
                font-weight: 600;
                display: inline-block;
                @media #{$querySp} {
                    @include fs(14);
                }
            }
            .-note {
                @include fs(12);
                line-height: 1.3;
                @media #{$querySp} {
                    @include fs(11);
                }
            }
            .-req {
                display: inline-block;
                @include fs(11);
                text-align: center;
                color: $clrWht;
                line-height: 15px;
                height: 17px;
                background-color: #c41945;
                padding: 2px 6px;
                margin-left: 10px;
                font-weight: 500;
            }
        }
        td {
            width: 72%;
            background-color: $clrWht;
            @media #{$querySp} {
                width: 100%;
            }
        }
    }
}

.c-txt_center {
    text-align: center;
}

input,
textarea {
    width: 100%;
    border: 1px solid #ccc;
    padding: 6px 10px;
    @include fs(14);
    @media #{$querySp} {
        padding: 5px 8px;
        @include fs(14);
    }
}

textarea {
    height: 8em;
}