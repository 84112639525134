// ----- fonts
$fontFamily: "Noto Sans JP",
"游ゴシック体",
YuGothic,
"游ゴシック Medium",
"Yu Gothic Medium",
"游ゴシック",
"Yu Gothic",
sans-serif;
// $fontFamilyMin: '游明朝',
// 'Yu Mincho',
// YuMincho,
// 'Hiragino Mincho Pro',
// serif;
$fontFamilyEn: "Roboto",
sans-serif;
// ----- path
$src: "/assets/";
// ----- line-height
$lhBase: 1.75;
$lhBaseSp: 1.75;
// ----- margin
$mgSmall: 20;
$mgNormal: 30;
$mgLarge: 60;
$mgContainer: 40;
// ----- layout
$widthBase: 960px;
$queryPc: "print, screen and (min-width: 768px)"; // PC
$queryPcIE: "_:-ms-lang(x)::-ms-backdrop,"; // IE11
$queryPcTab: "print, screen and (max-width: 1059px) and (min-width: 768px)"; // PC&TAB
$queryTab: "print, screen and (max-width: 1059px)"; // TAB
$querySp: "screen and (max-width: 767px)"; // SP
$querySpSE: "screen and (max-width: 320px)"; // iPhone SE