.p-register {
    &_head {
        .c-sec_copy {
            margin-bottom: 0;
        }
    }
    &_flow {
        padding-top: 0;
        .c-ttl_page {
            .-jp {
                @include fs(32);
                @media #{$querySp} {
                    @include fs(20);
                }
            }
        }
        .p-index_service_flow_list {
            margin-bottom: 60px;
            @media #{$querySp} {
                margin-bottom: 30px;
            }
        }
    }
    &_form {
        padding-bottom: 100px;
        @media #{$querySp} {
            padding-bottom: 48px;
        }
    }
}

.p-entry {
    &_form {
        &_cont {
            input,
            textarea {
                border: none;
                padding: 1em;
                background-color: $clrWht;
                line-height: 1.4;
                font-family: inherit;
                @media #{$querySp} {
                    padding: 16px 20px;
                    @include fs(16);
                }
            }
            textarea {
                border: none;
                border-radius: 6px;
                font-size: 15px;
                @media #{$querySp} {
                    font-size: 13px;
                }
            }
            input[type="radio"] {
                display: none;
                font-family: inherit;
                &+span {
                    position: relative;
                    z-index: 0;
                    padding-left: 25px;
                    cursor: pointer;
                    &::before {
                        display: block;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        width: 15px;
                        height: 15px;
                        border: 1px solid #ccc;
                        background-color: $clrWht;
                        border-radius: 50%;
                        content: "";
                    }
                }
                &:checked+span::after {
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 5px;
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    background-color: $clrLpMain;
                    content: "";
                }
            }
            input[type="checkbox"] {
                display: none;
                font-family: inherit;
                &+span {
                    position: relative;
                    z-index: 0;
                    padding-left: 25px;
                    cursor: pointer;
                    &::before {
                        display: block;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        width: 15px;
                        height: 15px;
                        border: 1px solid #aaa;
                        border-radius: 2px;
                        content: "";
                    }
                }
                &:checked+span::before {
                    // background-color: $clrLpMain;
                }
                &:checked+span::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 6px;
                    left: 2px;
                    width: 14px;
                    height: 16px;
                    background-image: url(../img/lp/icn_check.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }
        }
        &_submit {
            &_wrap {
                text-align: center;
                margin-top: 40px;
                @media #{$querySp} {
                    &.-confirm {
                        margin-top: 24px;
                    }
                }
            }
            color: $clrWht;
            font-weight: bold;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 320px;
            height: 56px;
            border-radius: 28px;
            background: rgb(255, 153, 0);
            background: linear-gradient(135deg, rgba(255, 153, 0, 1) 25%, rgba(240, 95, 71, 1) 35%, rgba(255, 153, 0, 1) 200%);
            background-size: 200% 100%;
            margin: auto;
            border: none;
            cursor: pointer;
            a {
                color: $clrWht;
            }
            &:hover {
                transition: all .4s;
                background-position: 100% 0;
            }
            @media #{$querySp} {
                min-width: unset;
                width: 80%;
                height: 45px;
            }
            &_back {
                color: #888;
                font-weight: bold;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 200px;
                height: 56px;
                border-radius: 28px;
                border: 1px solid #aaa!important;
                background: $clrWht;
                margin: auto;
                border: none;
                cursor: pointer;
                @media #{$querySp} {
                    min-width: unset;
                    width: 40%;
                    height: 45px;
                    margin-bottom: 30px;
                }
            }
        }
        &_input {
            width: 100%;
            font-weight: normal;
            @media #{$querySp} {}
        }
        &_txtarea {
            width: 100%;
            height: 240px;
        }
        &_select {
            display: inline-block;
            position: relative;
            border: none;
            vertical-align: middle;
            margin-bottom: 14px;
            position: relative;
            &::before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-right: 7px solid transparent;
                border-left: 7px solid transparent;
                border-top: 12px solid $clrLpBase;
                border-bottom: 0;
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
            }
            select {
                font-family: inherit;
                appearance: none;
                width: 180px;
                padding: 1em;
                border: none;
                outline: 0;
                background: $clrWht;
                background-image: none;
                box-shadow: none;
                text-indent: 0.01px;
                text-overflow: ellipsis; // 文字の省略
                cursor: pointer;
                @media screen and (max-width: 767px) {
                    padding: 16px 20px;
                    @include fs(16);
                }
            }
        }
        &_zip {
            width: 180px;
        }
        &_th {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            p {
                font-weight: bold;
                width: calc(100% - 52px);
                @media #{$querySp} {
                    @include fs(16);
                }
                span {
                    display: block;
                    // color: #acbbc3;
                    color: #888;
                    @include fs(14);
                    font-weight: 400;
                    line-height: 1.4;
                    margin-top: 1em;
                }
            }
        }
        &_row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            span {
                display: inline-block;
                @include fs(14);
                margin-left: 12px;
            }
        }
        &_choice {
            .p-entry_form_row {
                margin-bottom: 14px;
                p {
                    width: 80px;
                }
                input {
                    width: calc(100% - 80px)
                }
            }
        }
        &_agree {
            text-align: center;
            @media #{$querySp} {
                @include fs(16);
            }
        }
        &_btn {
            text-align: center;
            margin-top: 60px;
            @media #{$querySp} {
                margin-top: 32px;
            }
        }
    }
}

.-req {
    display: inline-block;
    font-weight: 400;
    @include fs(14);
    color: $clrWht;
    background-color: $clrLpRed;
    width: 42px;
    height: 24px;
    line-height: 23px;
    text-align: center;
    margin-left: 10px;
}

.-opt {
    display: inline-block;
    font-weight: 400;
    @include fs(14);
    color: $clrWht;
    background-color: $clrLpBase;
    width: 42px;
    height: 24px;
    line-height: 23px;
    text-align: center;
    margin-left: 10px;
    @media #{$querySp} {
        @include fs(11);
    }
}

.p-privacy {
    margin: 60px 0 30px;
    border: 1px solid #d5d8d9;
    @media #{$querySp} {
        margin: 40px 0 20px;
    }
    &_inner {
        height: 160px;
        overflow-x: scroll;
        background-color: $clrWht;
        padding: 0 45px 0 27px;
        border-top: 1em solid $clrWht;
        border-bottom: 1em solid $clrWht;
        scrollbar-color: #d5d8d9 #fff;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            width: 8px;
            border-radius: 4px;
            @media #{$querySp} {
                width: 6px;
                border-radius: 3px;
            }
        }
        &::-webkit-scrollbar-track {
            background-color: #fff;
            border-radius: 4px;
            @media #{$querySp} {
                border-radius: 3px;
            }
        }
        &::-webkit-scrollbar-thumb {
            background-color: #d5d8d9;
            border-radius: 4px;
            @media #{$querySp} {
                border-radius: 3px;
            }
        }
        .-block {
            &:not(:first-child) {
                margin-top: 1em;
            }
            h4 {
                font-weight: 900;
            }
            ul {
                li {
                    &:not(:first-child) {
                        margin-top: 0.5em;
                    }
                }
            }
        }
    }
}